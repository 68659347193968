import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Shoulder Press 2-2-2-2-2\\@90% 1RM`}</p>
    <p>{`&`}</p>
    <p>{`Stricted Pullups 2-2-2-2-2 (weighted if possible)`}</p>
    <p>{`then,`}</p>
    <p>{`21-15-9 reps each of:`}</p>
    <p>{`Bench Press (155/95)`}</p>
    <p>{`CTB Pullups`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Personal Training, Elements, and Massage Gift Certificates now
available for the Holidays!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our 2nd Annual Pre Open in house competition will start January 1st
so great ready! RX Men, RX Women, scaled, and masters (50+) divisions.
More details to come.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      